import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return [];
  }

  connect() {
    this.slideout = document.querySelector('.nav-content-component--full');
  }

  disconnect() {
    this.slideout = null;
  }

  open() {
    // hack body
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;

    this.slideout.style.display = '';
    this.slideout.classList.remove('nav-content-component--hidden');
  }

  close() {
    // unhack body, scroll to same position
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);

    this.slideout.style.display = 'none';
    this.slideout.classList.add('nav-content-component--hidden');
  }

  changeLanguage(e) {
    const newLocale = e.target.value;
    const currentLocale = this.data.get('locale') || 'en';

    if (currentLocale !== newLocale) {
      const newUrl = JSON.parse(this.data.get('localizedRoutes'))[newLocale];
      if (newUrl) {
        window.location.href = newUrl;
      } else {
        window.location.reload();
      }
    }
  }
}
